footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--header-background);
    color: var(--header-color);
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .social-media {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .social-media a {
    color: var(--header-color);
    transition: color 0.3s;
  }
  
  .social-media a:hover {
    color: var(--button-hover-background);
  }
  
  p {
    margin: 0;
    font-size: 1rem;
  }
  