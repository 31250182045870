.projects-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  
  margin: 0;
  transition: background-color 0.3s, color 0.3s;
}

.light-mode .projects-section {
  background: #fff;
  color: #000;
}

.dark-mode .projects-section {
  background: #111;
  color: #fff;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  animation: fadeInUp 1s ease-in-out;
}

.project-date {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 8px;
}

.project-card {
  background: var(--background-color);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s, color 0.3s;
  opacity: 0;
  animation: fadeIn 1s forwards;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 320px;
  position: relative;
}

.light-mode .project-card {
  background: #fff;
  color: #000;
}

.dark-mode .project-card {
  background: #222;
  color: #fff;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.project-description {
  font-size: 14px;
  margin-bottom: 15px;
}

.read-more {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  font-family: inherit;
  color: var(--header-color);
  transition: transform 0.2s ease, color 0.3s ease;
  align-self: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
}

.read-more:hover {
  color: var(--button-hover-background);
  transform: scale(1.05);
}

.read-more-icon {
  font-size: 14px;
}

.tech-stack-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  margin-top: auto;
  padding-top: 10px;
}

.tech-stack {
  background: var(--button-background);
  color: var(--button-color);
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.2s ease;
}

.tech-stack:hover {
  background: var(--button-hover-background);
  transform: scale(1.05);
}

.project-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 10px;
  width: 100%;
}

.project-links {
  display: flex;
  gap: 15px;
}

.btn {
  display: inline-flex;
  align-items: center;
  color: white;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.5rem;
  transition: background-color 0.3s;
}

.btn-outline {
  background: none;
  border: none;
  padding: 0;
  color: var(--header-color); 
  font-size: 1.5rem; 
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  transition: color 0.3s ease, transform 0.2s ease;
}

.btn-outline:hover {
  color: var(--button-hover-background); /* highlight color */
  transform: scale(1.2); /* optional zoom effect */
  background: none !important; /* just in case */
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 8px;
  font-size: 12px;
  position: absolute;
  z-index: 10;
  bottom: 125%; /* position above the icon */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-wrapper:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive */
@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
    gap: 10px; 
  }
  .project-card{
    max-width: 300px;
  }
  .project-card:last-child {
    margin-bottom: 80px;
  }
}

@media (min-width: 769px) {
  .project-card:nth-last-child(-n+3) {
    margin-bottom: 80px;
  }
  .projects-grid{
    padding-left: 20px;
  }
}