.project-details-container {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 90px 20px;
  transition: background-color 0.3s;
}

.project-details {
  max-width: 900px;
  padding: 50px;
  margin-bottom: 80px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: left;
  transition: background-color 0.3s, color 0.3s;
}

.light-mode .project-details {
  background: #fff;
  color: #000;
}

.dark-mode .project-details {
  background: #222;
  color: #fff;
}

.light-mode .project-title {
  color: #000;
}

.dark-mode .project-title {
  color: #ff69b4;
}
.project-meta {
  font-size: 14px;
  margin-bottom: 15px;
}

.project-video {
  width: 100%;
  max-height: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.project-content {
  font-size: 16px;
  line-height: 1.6;
}

.project-carousel {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.carousel-image {
  display: block;
  max-width: 500px;   
  max-height: 300px; 
  margin: 0 auto;
  object-fit: contain;
  border-radius: 8px;
}


.slick-prev, .slick-next {
  width: 50px;
  height: 50px;
  z-index: 2;
}

.light-mode .slick-prev:before,
.light-mode .slick-next:before {
  color: #ff69b4;
  filter: brightness(1);
}

.dark-mode .slick-prev:before,
.dark-mode .slick-next:before {
  color: #ff69b4;
  filter: brightness(1.5);
}

.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  filter: brightness(1);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .project-details {
    max-width: 750px;
    padding: 40px;
  }
}

@media (max-width: 768px) {
  .project-details {
    max-width: 80%;
    padding: 30px;
  }
  .project-video {
    max-height: 300px;
  }
  .slick-prev {
    left: -40px;
  }
  
  .slick-next {
    right: -40px;
  }
}