.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; /* ensures it's on top of everything */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--header-background);
  color: var(--header-color);
  transition: background-color 0.3s, color 0.3s;
}

.hamburger {
  display: none;
}

.nav {
  display: flex;
  align-items: center;
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

#projects{
  scroll-margin-top: 80px; 
}

#timeline{
  scroll-margin-top: 20px; 
}

.nav-item {
  margin-left: 15px; /* Reduced margin */
}

.nav-item a {
  text-decoration: none;
  color: var(--header-color);
  font-weight: 600;
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center vertically */
  height: 60px; /* Match the height of the logo */
  transition: background-color 0.3s;
}
.nav-link {
  background: none;
  border: none;
  font: inherit;
  font-weight: 600;
  font-size: inherit;
  line-height: inherit;
  color: var(--header-color);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0;
  transition: background-color 0.3s;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1); /* matches hover effect */
}
.logo {
  width: 120px; /* Adjusted size */
  height: auto;
}

.toggle-switch {
  display: flex;
  align-items: center;
}

#toggle {
  display: none;
}

.toggle-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px; 
  height: 30px; 
  background-color: #ff69b4; 
  border-radius: 50%; 
  transition: background-color 0.3s;
}

.icon {
  font-size: 20px; /* Adjusted size */
  color: white; /* White icons */
}

#toggle:checked + .toggle-label {
  background-color: #ff69b4; /* Blue violet background when checked */
}

/* Media queries for mobile-specific styles */
@media (max-width: 600px) {
  .nav-item a {
    height: 5px; /* Adjust height for smaller devices */
    padding: 20px 0; /* Adjust padding for alignment */
  }

  .nav-list.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 10px;
    right: 10px;
    padding: 20px 0;
    z-index: 9998;
    border-radius: 12px;
    backdrop-filter: blur(10px);
    animation: fadeSlideDown 0.3s ease-out;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
  
  /* Light Mode */
  .light-mode .nav-list.open {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  /* Dark Mode */
  .dark-mode .nav-list.open {
    background-color: rgba(0, 0, 0, 0.85);
  }
  
  .logo {
    margin-top: 30px;
    width: 70px; 
    height: auto;
  }

  .toggle-label .light-mode .dark-mode{
    width: 25px; 
    height: 25px; 
    color: #ff69b4;
  }

  .toggle-switch {
    margin-top: 18px; 
    margin-bottom: 20px; 
  }

  .nav-list {
    display: none;
  }

  .hamburger {
    display: block;
    font-size: 24px;
    background: transparent;
    border: none;
    color: var(--header-color);
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 60px;
    z-index: 10000;
  }

  .hamburger:focus,
  .hamburger:active {
    background: transparent;
    outline: none;
    box-shadow: none;
  }

  .hamburger:focus-visible {
    outline: none;
  }
  
  .nav-item {
    margin-left: 0;
    margin-top: 10px;
  }

  .header {
    height: 80px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .nav {
    margin-bottom: 10px;
  }
 
  .icon {
    font-size: 20px; 
    color: white;
  }
  @keyframes fadeSlideDown {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
}