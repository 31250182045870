/* Reset some default styles */
body, h1, h2, p, ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

.light-mode {
  --background-color: #f7f7f7;
  --text-color: #333;
  --header-background: #f7f7f7; /* Light background for header */
  --header-color: #ff69b4;
  --footer-background: #f7f7f7; /* Light background for footer */
  --button-background: #ffb6c1;
  --button-hover-background: #ff69b4;
  --button-color: #fff;
}

.dark-mode {
  --background-color: #000;
  --text-color: #ff69b4;
  --header-background: #000; /* Dark background for header */
  --header-color: #ff69b4;
  --footer-background: #000; /* Dark background for footer */
  --button-background: #ff69b4;
  --button-hover-background: #ffb6c1;
  --button-color: #000;
}

header nav ul {
  list-style-type: none;
  display: flex;
  gap: 10px; /* Reduced gap */
}

header nav ul li a {
  color: var(--header-color);
  font-weight: bold;
}

header button {
  background-color: var(--button-background);
  border: none;
  padding: 10px 15px; /* Adjusted padding */
  color: var(--button-color);
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 5px; /* Slightly rounded corners */
}

header button:hover {
  background-color: var(--button-hover-background);
  transform: scale(1.05);
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.social-media a {
  color: var(--header-color);
  transition: color 0.3s;
}

.social-media a:hover {
  color: var(--button-hover-background);
}

p {
  margin: 0;
  font-size: 1rem;
}

.container {
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 2.5rem;
  color: var(--header-color);
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  color: var(--header-color);
  margin-bottom: 20px;
}

p {
  font-size: 1rem;
  margin-bottom: 20px;
}

a {
  color: var(--header-color);
  font-weight: bold;
}

section {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  header {
    flex-direction: column;
  }

  header nav ul {
    flex-direction: column;
    align-items: center;
  }

  header button {
    margin-top: 10px;
  }
}
