.home-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s;
  margin: 0;
  padding: 40px 20px;
}

.home-section.light {
  background-color: #fff;
  color: #000;
}

.home-section.dark {
  background-color: #000;
  color: #fff;
}

.introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 2s ease-in-out;
}

.profile-image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-bottom: 20px;
  animation: slideIn 2s ease-in-out;
}

.intro-text h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  animation: fadeInText 3s ease-in-out;
}

.intro-text p {
  font-size: 1.2rem;
  animation: fadeInText 4s ease-in-out;
}

.button-group {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap; 
}

.btn {
  background-color: #ff69b4;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #e456a0;
}

.btn-outline {
  background-color: transparent;
  border: 2px solid #ff69b4;
  color: #ff69b4;
}

.btn-outline:hover {
  background-color: #ff69b4;
  color: white;
}

/* Keyframes for the animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Media query for mobile view */
@media (max-width: 600px) {
  .home-section {
    height: 100vh;
    padding: 20px 10px;
    justify-content: center;
    text-align: center;
  }
  .intro-text p {
    padding-bottom: 50px; 
  }
  .button-group {
    flex-direction: column;
    gap: 10px;
  }  
  .profile-image {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    animation: slideIn 2s ease-in-out;
  }
}
